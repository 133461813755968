import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

// Components
import { Link, graphql } from "gatsby"

const SubjectsPage = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <div>
    <div>
      <h1>Subjects</h1>
      <ul>
        {group.map(subject => (
          <li key={subject.fieldValue}>
            <Link to={`/subjects/${_.kebabCase(subject.fieldValue)}/`}>
              {subject.fieldValue} ({subject.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

SubjectsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default SubjectsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___subject) {
        fieldValue
        totalCount
      }
    }
  }
`
